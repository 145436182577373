<template>
  <div class="quizz-activity">
    <div class="quizz-wrapper">
      <div class="head">
        <div class="top" v-if="!showPagination">
          <slot name="top" v-bind="currentQuestion" :questionIndex="questionIndex + 1" :questionsCount="questions.length">
            <p class="title">{{ quizzTitle }}</p>
            <p class="page-index">
              {{ questionIndex + 1 }} / {{ questions.length }}
            </p>
          </slot>
        </div>
        <slot name="question" v-bind="{ ...currentQuestion, showAnswers }">
          <div class="question-wrapper" :style="{ backgroundImage: `url('${currentQuestion.image}')` }">
            <div class="question">
              {{ currentQuestion.label }}
            </div>
          </div>
        </slot>
      </div>
      <div v-if="!isInput" class="answers training-quizz-wrapper" :class="{ 'show-answers': showAnswers, 'animate-answers': isMultiple }">
        <div v-for="answer in currentAnswers"
          :key="answer.name"
          class="answer quizz-answer"
          :class="{ checked: isAnswerChecked(answer.name), right: isAnswerRight(answer.name), big: answer.big, small: answer.small }"
          @click="setAnswer(answer.name)"
        >
          <slot name="answer" v-bind="answer" :isRight="isAnswerRight(answer.name)" :isChecked="isAnswerChecked(answer.name)" :showAnswers="showAnswers">
            <div class="image" v-html="answer.image"></div>
          </slot>
        </div>
      </div>
      <div v-if="isInput">
        <div v-if="!showAnswers" class="input-wrapper">
          <label :for="'input' + currentQuestion.name">{{ inputLabel }}</label>
          <div class="input">
            <input-text :input-type="inputType" :name="'input' + currentQuestion.name" :show-placeholder="true" :model-value="currentUserAnswer" @update:model-value="setAnswer" @submit="setAnswer"></input-text>
            <p class="input-unit">&nbsp;{{ currentQuestion.content.resultUnit ? currentQuestion.content.resultUnit : inputUnit }}</p>
          </div>
        </div>
        <div v-else class="input-answer-wrapper">
          <slot name="input-answer-wrapper" v-bind="currentQuestion" :isRight="isInputAnswerRight">
            {{ currentQuestion.rightAnswer }}
          </slot>
        </div>
      </div>
      <div v-show="enableCorrection && showAnswers" class="correction-wrapper" :class="{ right: isCurrentAnswerRight, 'animate-correction': isMultiple }">
        <slot name="correction" v-bind="currentQuestion"></slot>
      </div>
      <div class="button-wrapper" v-if="!showPagination || isInput" :class="{ 'show-answers': showAnswers }">
        <app-button-layout v-if="!showAnswers" @click="validateAnswers" :disabled="!isCurrentQuestionAnswered">
          {{ $t('global.navigation.validate') }}
        </app-button-layout>
        <app-button-layout v-else-if="!showPagination && showAnswers" @click="nextQuestion">
          {{ $t('global.navigation.resume') }}
        </app-button-layout>
      </div>
      <div class="pagination" v-if="showPagination">
        <slot name="pagination">
          <div class="prev-nav-button">
            <prev-button-layout @click="prevQuestion" v-show="questionIndex > 0"></prev-button-layout>
          </div>
          <p class="page-index">
            {{ questionIndex + 1 }} / {{ questions.length }}
          </p>
          <div class="next-nav-button">
            <next-button-layout @click="nextQuestion" v-show="!isLastQuestion"></next-button-layout>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '@/i18n'
import AppButtonLayout from '@/components/buttons/AppButtonLayout'
import PrevButtonLayout from '@/components/buttons/PrevButtonLayout'
import NextButtonLayout from '@/components/buttons/NextButtonLayout'
import InputText from '@/components/input/InputText'

export default {
  name: 'QuizzActivity',
  components: { InputText, AppButtonLayout, PrevButtonLayout, NextButtonLayout },
  emits: ['questionIndexUpdated', 'quizz-finished'],
  props: {
    questions: {
      type: Array,
      required: true
    },
    quizzTitle: {
      type: String,
      required: false,
      default () {
        return i18n.global.t('training.global.quizzTitle')
      }
    },
    answers: {
      type: Array,
      required: false
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: true
    },
    isInput: {
      type: Boolean,
      required: false,
      default: false
    },
    inputType: {
      type: String,
      required: false,
      default: 'number'
    },
    inputUnit: {
      type: String,
      required: false,
      default: ''
    },
    inputLabel: {
      type: String,
      required: false,
      default: ''
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: false
    },
    enableCorrection: {
      type: Boolean,
      required: false,
      default: false
    },
    shuffleAnswers: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      questionIndex: 0,
      userAnswers: {},
      showAnswersIfMultiple: false,
      showValidationAnimation: false
    }
  },
  computed: {
    currentQuestion () {
      return this.questions[this.questionIndex]
    },
    currentAnswers () {
      let toReturn = []
      if (this.answers) toReturn = this.answers
      else toReturn = this.currentQuestion.answers
      return this.shuffleAnswers
        ? this.shuffleArray(toReturn)
        : toReturn
    },
    showAnswers () {
      return this.isMultiple || this.isInput
        ? this.showAnswersIfMultiple
        : this.isCurrentQuestionAnswered
    },
    isCurrentQuestionAnswered () {
      return this.isMultiple
        ? this.currentUserAnswer?.length > 0
        : this.currentUserAnswer !== undefined && this.currentUserAnswer !== ''
    },
    isLastQuestion () {
      return this.questionIndex >= this.questions.length - 1
    },
    currentUserAnswer () {
      return this.userAnswers[this.currentQuestion.name]
    },
    score () {
      return this.isMultiple
        ? this.scoreMultiple
        : this.scoreSimple
    },
    scoreSimple () {
      return this.questions.filter(question => question.rightAnswer === this.userAnswers[question.name])
        .length
    },
    scoreMultiple () {
      let score = 0
      this.questions.forEach((question) => {
        const isSameNumberOfAnswers = this.userAnswers[question.name]?.length === question.rightAnswer.length
        const isEveryUserAnswerInRightAnswers = question.rightAnswer.every(rightAnswer => this.userAnswers[question.name]?.includes(rightAnswer))
        if (isSameNumberOfAnswers && isEveryUserAnswerInRightAnswers) {
          score++
        }
      })
      return score
    },
    isCurrentAnswerRight () {
      if (this.isInput) return this.isInputAnswerRight
      if (this.isMultiple) {
        return this.currentQuestion?.rightAnswer.every(rightAnswer => this.currentUserAnswer?.includes(rightAnswer)) &&
          this.currentQuestion?.rightAnswer.length === this.currentUserAnswer?.length
      }
      return this.currentQuestion.rightAnswer === this.currentUserAnswer
    },
    isInputAnswerRight () {
      return this.currentQuestion.rightAnswer === Number(this.userAnswers[this.currentQuestion.name])
    }
  },
  methods: {
    shuffleArray (array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array
    },
    isAnswerChecked (answerName) {
      return this.isMultiple
        ? this.currentUserAnswer?.includes(answerName)
        : this.currentUserAnswer === answerName
    },
    isAnswerRight (answerName) {
      return this.isMultiple
        ? this.currentQuestion.rightAnswer.includes(answerName)
        : this.currentQuestion.rightAnswer === answerName
    },
    validateAnswers () {
      if (this.isCurrentQuestionAnswered) {
        this.showAnswersIfMultiple = true
      }
    },
    setAnswer (answerName) {
      if (this.showAnswersIfMultiple) return
      if (this.isMultiple) {
        this.setAnswerMultiple(answerName)
      } else {
        this.userAnswers[this.currentQuestion.name] = answerName
        if (!this.isInput) {
          this.showAnswersIfMultiple = true
        }
      }
    },
    setAnswerMultiple (answerName) {
      if (this.userAnswers[this.currentQuestion.name]) {
        const answerIndex = this.userAnswers[this.currentQuestion.name].indexOf(answerName)
        if (answerIndex !== -1) {
          this.userAnswers[this.currentQuestion.name].splice(answerIndex, 1)
        } else {
          this.userAnswers[this.currentQuestion.name].push(answerName)
        }
      } else {
        this.userAnswers[this.currentQuestion.name] = []
        this.userAnswers[this.currentQuestion.name].push(answerName)
      }
    },
    nextQuestion () {
      if (this.isLastQuestion) {
        this.$emit('quizz-finished', { score: this.score })
        return
      }
      this.showAnswersIfMultiple = false
      this.questionIndex++
      this.$emit('questionIndexUpdated')
      if (this.currentUserAnswer) {
        if (this.isInput || this.isMultiple) {
          this.showAnswersIfMultiple = true
        }
      }
    },
    prevQuestion () {
      if (this.questionIndex > 0) {
        this.questionIndex--
        this.$emit('questionIndex:updated')
        if (this.currentUserAnswer) {
          if (this.isInput || this.isMultiple) {
            this.showAnswersIfMultiple = true
          }
        } else {
          this.showAnswersIfMultiple = false
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.quizz-activity {
  background-color: #fff;
  border-radius: 0 0 $radius-xl $radius-xl;
  padding: $space-sm $space-sm $space-m $space-sm;
  height: 100%;
  .quizz-wrapper {
    max-width: 380px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: $space-s;
    .head {
      .top {
        position: relative;
        font-size: $fz-xs;
        font-weight: $fw-l;
        .title {
          text-align: center;
          color: $c-locked;
          text-transform: uppercase;
        }
        .page-index {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          color: #fff;
          background-color: $c-locked;
          border-radius: $radius;
          padding: 3px $space-s;
        }
      }
    }
    .question-wrapper {
      min-height: 130px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-repeat: no-repeat;
      background-position: bottom left;
      .question {
        color: $c-text-dark;
        font-size: $fz-ml;
        font-weight: $fw-l;
        padding: $space-xs $space-l;
        text-align: center;
      }
    }
    .answers {
      margin-top: $space-s;
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      gap: $space-xs;
      margin-bottom: $space-m;
      .answer {
        height: 110px;
        grid-column-start: span 4;
        &.small {
          grid-column-start: span 3;
        }
        &.big {
          grid-column-start: span 6;
        }
        .image {
          width: 100%;
          max-width: 120px;
        }
      }
      &.animate-answers.show-answers {
        .answer {
          transition: border-color 0.2s;
        }
        .answer.right {
          animation-name: win;
          animation-duration: 1.2s;
        }
        .answer:not(.right) {
          background-color: $c-background;
          color: $c-text-light;
          border: none;
        }
      }
      &:not(.show-answers) {
        .answer {
          transition: border-color 0.2s;
          &:hover {
            border-color: $c-secondary;
            box-shadow: none;
          }
        }
      }
    }
    .input-wrapper {
      margin: $space-m 0;
      label {
        display: block;
        margin-bottom: $space-xs;
        font-size: $fz-s;
        font-weight: $fw-l;
        text-transform: uppercase;
      }
      .input {
        display: flex;
        align-items: center;
        input.input-text {
          width: 100%;
          max-width: 220px;
          text-align: center;
          color: $c-text-light;
          font-size: $fz-ml;
        }
        .input-unit {
          color: $c-text-light;
          font-size: $fz-ml;
          font-weight: $fw-l;
        }
      }
    }
    .correction-wrapper {
      &.animate-correction {
        transform-origin: top center;
        animation-name: appear;
        animation-duration: 1.5s;
      }
      padding: $space-m $space-ml;
      border-radius: $radius;
      font-size: $fz-s;
      margin-bottom: $space-sm;
      background-color: $c-background;
    }
    .button-wrapper {
      .app-button-layout {
        width: 100%;
      }
      &.show-answers {
        .app-button-layout {
          animation-name: blink;
          animation-duration: 1.8s;
        }
      }
    }
  }
}

@media (min-width: $bp-phone) {
  .quizz-activity {
    border-radius: $radius;
    max-width: 520px;
    margin: $space-s auto;
    padding: $space-sm $space-ml $space-m $space-ml;
  }
}
</style>

<style lang="scss">
.quizz-activity {
  .answers .answer {
    .image svg {
      width: 100%;
      min-height: 60px;
      max-height: 80px;
    }
  }
}
</style>
