<template>
  <input :type="inputType"
    :id="name"
    :autocomplete="autoComplete ? 'autocomplete' : null"
    :value="modelValue"
    class="input-text"
    :placeholder="showPlaceholder ? placeholder : null"
    @input="handleChange($event.target.value)"
    v-on:keyup.enter="$emit('submit', $event.target.value)"
    ref="input"
  >
</template>

<script>
export default {
  name: 'InputText',
  emits: ['update:modelValue', 'submit'],
  props: {
    modelValue: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default () {
        return ''
      }
    },
    inputType: {
      type: String,
      required: false,
      default () {
        return 'text'
      }
    },
    placeholder: {
      type: String,
      required: false,
      default () {
        return '...'
      }
    },
    autoComplete: {
      type: Boolean,
      required: false,
      default () {
        return false
      }
    },
    showPlaceholder: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    handleChange (value) {
      this.$emit('update:modelValue', value)
    }
  }
}
</script>

<style lang="scss" scoped>
input.input-text {
  padding: $space-s;
  color: $c-text-light;
  height: 56px;
  border-radius: 8px;
  border: 1px solid $c-border;
  background-color: #fff;
  font-size: $fz-m;
}
</style>
